function imgReport(url) {
  if (!url || typeof url !== "string") {
    return console.error("params invalid, url must be non-empty string");
  }
  let image = new Image();
  image.onload = function () {
    console.info("report success");
  };
  image.crossOrigin = "Anonymous";
  image.src = url;
}

function sendBeacon(url, data) {
  let headers = {
    type: "application/json",
  };
  let blob = new Blob([data], headers);
  return navigator.sendBeacon(url, blob);
}

function xmlLoadData(url, data) {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  xhr.onreadystatechange = function (res) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      console.info("report success, callback result is: ", res);
    }
  };
  xhr.onerror = function (error) {
    console.error("report by XMLHttpRequest fail, reason is : ", error);
  };
  xhr.send(data);
}

function ajax(url, dataMessage, dataStr) {
  try {
    xmlLoadData(url, dataMessage);
  } catch (error) {
    console.error("report by XMLHttpRequest fail, reason is : ", error);
    imgReport(`${url}?message=${dataStr}`);
  }
}

export function send(url, data, isDevtoolsEnabled = false) {
  const dataStr = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  const dataMessage = JSON.stringify({ message: dataStr });
  if (navigator.sendBeacon && !isDevtoolsEnabled) {
    try {
      if (!sendBeacon(url, dataMessage)) {
        ajax(url, dataMessage, dataStr);
      }
    } catch (error) {
      console.error("report by sendBeacon fail, reason is : ", error);
      ajax(url, dataMessage, dataStr);
    }
  } else {
    ajax(url, dataMessage, dataStr);
  }
}
