const x64hash128 = require("./x64hash128");
const { navigator, screen, devicePixelRatio } = window;
const dataBaseKeyMap = [
  { key: "userAgent", getData: "getUserAgent" },
  { key: "language", getData: "getSystemLanguage" },
  { key: "colorDepth", getData: "getColorDepth" },
  { key: "deviceMemory", getData: "getDeviceMemory" },
  { key: "pixelRatio", getData: "getPixelRatio" },
  { key: "timeZoneOffset", getData: "getTimeZoneOffset" },
  { key: "screenSize", getData: "getScreenSize" },
  { key: "platform", getData: "getPlatform" },
  { key: "cpu", getData: "getCpu" },
  { key: "vendor", getData: "getVendor" },
  { key: "hardwareConcurrency", getData: "getHardwareConcurrency" },
  { key: "canvasFingerprint", getData: "getCanvasFingerprint" },
];

const options = {
  NOT_AVAILABLE: "NOT_AVAILABLE",
};

const DataBaseFunMap = {
  getUserAgent() {
    return navigator.userAgent;
  },
  getSystemLanguage() {
    return (
      navigator.language ||
      navigator.userLanguage ||
      navigator.browserLanguage ||
      navigator.systemLanguage ||
      options.NOT_AVAILABLE
    );
  },
  getColorDepth() {
    return screen.colorDepth || options.NOT_AVAILABLE;
  },
  getDeviceMemory() {
    return navigator.deviceMemory || options.NOT_AVAILABLE;
  },
  getPixelRatio() {
    return devicePixelRatio || options.NOT_AVAILABLE;
  },
  getTimeZoneOffset() {
    return new Date().getTimezoneOffset();
  },
  getScreenSize() {
    return [screen.width, screen.height, screen.availHeight, screen.availWidth].join();
  },
  getPlatform() {
    return navigator.platform || options.NOT_AVAILABLE;
  },
  getCpu() {
    return navigator.oscpu || options.NOT_AVAILABLE;
  },
  getVendor() {
    return navigator.vendor || options.NOT_AVAILABLE;
  },
  getHardwareConcurrency() {
    return navigator.hardwareConcurrency || options.NOT_AVAILABLE;
  },
  getCanvasFingerprint() {
    function bin2hex(s) {
      let i,
        l,
        o = "",
        n;
      s += "";
      for (i = 0, l = s.length; i < l; i++) {
        n = s.charCodeAt(i).toString(16);
        o += n.length < 2 ? "0" + n : n;
      }
      return o;
    }
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d") || {};
    const txt = "SHAREit-Beyla";
    ctx.textBaseline = "top";
    ctx.font = "14px 'Arial'";
    ctx.fillStyle = "#f60";
    ctx.fillRect(0, 1, 62, 20);
    ctx.fillStyle = "#069";
    ctx.fillText(txt, 2, 15);
    ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
    ctx.fillText(txt, 4, 17);
    ctx.fillRect(125, 1, 62, 20);
    const b64 = canvas.toDataURL().replace("data:image/png;base64,", "");
    const bin = window.atob(b64);
    const crc = bin2hex(bin.slice(-16, -12));
    return crc;
  },
};

const getFingerprint = function () {
  let database = "";
  let datalist = [];
  for (let i = 0; i < dataBaseKeyMap.length; i++) {
    const value = DataBaseFunMap[dataBaseKeyMap[i].getData]();
    datalist.push({
      key: dataBaseKeyMap[i].key,
      value,
    });
    database += String(value);
  }
  const seed = 30;
  return x64hash128(database, seed);
};

module.exports = getFingerprint;
