import { version } from "../package.json";
import { envMap } from "./utils/env.js";
import { getPerformance, install as performanceInstall } from "./plugins/performance";
import { validateBeylaOptions, validateBuriedPointParams } from "./utils/checkParams";
import { PERFORMANCE_PLUGIN, SHAREIT_BEYLA_ID } from "./utils/constant";
import { getCookie, setCookie } from "./utils/cookie";
import DeviceInfo from "./utils/device";
import getFingerprint from "./utils/fingerprint";
import { send } from "./utils/request";
import { getAllUrlParams } from "./utils/tools";
export default class Beyla {
  constructor(options = {}) {
    let beylaId = getCookie(SHAREIT_BEYLA_ID);
    if (!beylaId) {
      beylaId = `${getFingerprint()}-${Math.random().toFixed(8)}`;
      // 参考 Ga clientId 默认2年过期
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference?hl=zh-cn#beylaId
      setCookie(SHAREIT_BEYLA_ID, beylaId, 2 * 365);
    }
    this.beylaId = beylaId;
    this.config = {
      appId: "sdktest",
      plugins: [],
      env: "prod",
      beforeSend: null,
      version: version,
      strict: true,
      devtools: false,
    };
    this.basicReportObj = {
      project: options.project || "performance",
      logStore: options.logStore || "report",
      groupName: options.groupName || "web",
    };
    if (validateBeylaOptions(options)) {
      this.config = Object.assign({}, this.config, options);
    }
    this.url = envMap[this.config.env];
    if (this.config.devtools) {
      window.__BEYLA__DEVTOOLS__ = true;
    }
    // 处理插件
    // 插件总开关
    this.pluginSwitch = {
      performance: false,
      debug: false,
    };
    // 注册插件
    this._installPlugins();
    this.registerPageParams = undefined;
    // TODO 监听页面变化，自动上报当前页面的性能指标
    // window.beylaInstance = this;
  }

  _installPlugins() {
    // 性能插件
    if (this.config.plugins.includes(PERFORMANCE_PLUGIN)) {
      this.pluginSwitch.performance = true;
      performanceInstall().then(() => {
        this.reportFlow();
      });
    }
  }
  _send(type, data) {
    if (data && typeof data !== "object") return console.error("params invalid");
    // 如果存在beforeSend，并且执行结果是false，才会直接跳过上报。
    if (this.config.beforeSend && !this.config.beforeSend(logData)) {
      return console.info("beforeSend result false");
    }
    let inserData = {};

    if (type === "performance") {
      inserData = { performance: data };
    } else if (type === "custom") {
      inserData = { params: data };
      if (this.config.strict) {
        validateBuriedPointParams(data);
      }
    }
    const messageObj = Object.assign(
      {
        appId: this.config.appId,
        reportType: type || "custom",
        reportTime: new Date().getTime(),
        url: location.href,
        query: getAllUrlParams(),
        beylaId: this.beylaId,
        fingerprint: getFingerprint(),
        deviceInfo: new DeviceInfo().deviceData,
      },
      inserData,
      { publicParams: this.registerPageParams }
    );
    let logData = Object.assign({}, this.basicReportObj, {
      message: JSON.stringify(messageObj),
    });
    send(this.url, logData, this.config.devtools);
  }
  // 自定义上报
  report(params) {
    if (typeof params !== "object") {
      return console.error("params 参数必须为对象");
    }
    this._send("custom", params);
  }
  // 性能上报
  reportFlow() {
    if (this.pluginSwitch.performance) {
      const logData = getPerformance();
      this._send("performance", logData);
    } else {
      console.info("先要开启性能上报");
    }
  }
  // 设置事件公共属性
  registerPage(params = {}) {
    // 支持静态属性和动态属性
    let obj = {};
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        const element = params[key];
        if (Object.prototype.toString.call(element) === "[object Function]") {
          // 处理动态属性
          try {
            const ret = element() || null;
            if (ret) {
              obj[key] = ret;
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          obj[key] = element;
        }
      }
    }
    if (this.registerPageParams) {
      this.registerPageParams = Object.assign(this.registerPageParams, obj);
    } else {
      this.registerPageParams = obj;
    }
  }

  reportError(error, logData) {
    console.log(error, logData);
  }
  reportApiError(data) {
    console.log(data);
  }
}
