import { LOAD_EVENT } from "../utils/constant.js";
function getTiming(t) {
  let timing = {};
  // TCP 建立连接完成握手的时间
  timing.connect = t.connectEnd - t.connectStart;

  //【重要】页面加载完成的时间
  //【原因】这几乎代表了用户等待页面可用的时间
  timing.loadPage = t.loadEventEnd - t.navigationStart;

  //【重要】解析 DOM 树结构的时间
  //【原因】反省下你的 DOM 树嵌套是不是太多了！
  timing.domReady = t.domComplete - t.responseEnd;

  //【重要】重定向的时间
  //【原因】拒绝重定向！比如，http://example.com/ 就不该写成 http://example.com
  timing.redirect = t.redirectEnd - t.redirectStart;

  //【重要】DNS 查询时间
  //【原因】DNS 预加载做了么？页面内是不是使用了太多不同的域名导致域名查询的时间太长？
  // 可使用 HTML5 Prefetch 预查询 DNS ，见：[HTML5 prefetch](http://segmentfault.com/a/1190000000633364)
  timing.lookupDomain = t.domainLookupEnd - t.domainLookupStart;

  //【重要】读取页面第一个字节的时间
  //【原因】这可以理解为用户拿到你的资源占用的时间，加异地机房了么，加CDN 处理了么？加带宽了么？加 CPU 运算速度了么？
  // TTFB 即 Time To First Byte 的意思
  // 维基百科：https://en.wikipedia.org/wiki/Time_To_First_Byte
  timing.ttfb = t.responseStart - t.navigationStart;

  //【重要】内容加载完成的时间
  //【原因】页面内容经过 gzip 压缩了么，静态资源 css/js 等压缩了么？
  timing.request = t.responseEnd - t.requestStart;

  //【重要】执行 onload 回调函数的时间
  //【原因】是否太多不必要的操作都放到 onload 回调函数里执行了，考虑过延迟加载、按需加载的策略么？
  timing.loadEvent = t.loadEventEnd - t.loadEventStart;

  // DNS 缓存时间
  timing.appcache = t.domainLookupStart - t.fetchStart;

  // 卸载页面的时间
  timing.unloadEvent = t.unloadEventEnd - t.unloadEventStart;
  return timing;
}

function getResourceTiming() {
  try {
    let entries = window.performance.getEntriesByType("resource");
    let usefulType = ["navigation", "link", "css", "img", "script", "xmlhttprequest", "fetch", "iframe"];
    return entries
      .filter((entry) => usefulType.indexOf(entry.initiatorType) > -1)
      .map((entry) => ({
        name: entry.name,
        entryType: entry.entryType,
        initiatorType: entry.initiatorType,
        duration: entry.duration,
        nextHopProtocol: entry.nextHopProtocol,
        // 重定向的时间
        redirect: entry.redirectEnd - entry.redirectStart,

        // DNS 查询时间
        lookupDomain: entry.domainLookupEnd - entry.domainLookupStart,

        // 内容加载完成的时间
        request: entry.responseEnd - entry.requestStart,

        // TCP 建立连接完成握手的时间
        connect: entry.connectEnd - entry.connectStart,
      }));
  } catch (error) {
    console.error(error);
  }
}

function getPaintTiming() {
  try {
    let fp = performance.getEntriesByName("first-paint")[0]?.startTime;
    let fcp = performance.getEntriesByName("first-contentful-paint")[0]?.startTime;
    return {
      fp,
      fcp,
    };
  } catch (error) {
    console.error(error);
  }
}

export function getPerformance() {
  let performance = window.performance;
  if (!performance) {
    console.log("你的浏览器不支持 performance 接口");
    return;
  }
  //TODO: timing已经在web标准中废弃，要注意修订。
  let t = performance.timing;
  let m = performance.memory;
  let memory = {};
  if (m) {
    memory.usedJSHeapSize = m.usedJSHeapSize;
    memory.totalJSHeapSize = m.totalJSHeapSize;
    memory.jsHeapSizeLimit = m.jsHeapSizeLimit;
  }
  let performanceData = {
    timing: getTiming(t),
    memory,
    resourceTiming: getResourceTiming(),
    paintTiming: getPaintTiming(),
  };

  // performance: {frequency: "default", event:"load"}
  // frequency
  // default 只在首页默认上报一次。
  // all 每个页面（当url变化时），上报一次。
  // event:浏览器事件，决定上报时机
  return performanceData;
}

export function install(event = LOAD_EVENT, frequency = "default") {
  return new Promise((resolve, reject) => {
    try {
      window.addEventListener(event, () => {
        resolve(frequency);
      });
      // TODO: 监听页面变化，自动上报当前页面的性能指标
      // window.addEventListener("hashchange", () => {
      //   window.beylaInstance.reportFlow();
      // });
    } catch (error) {
      reject(error);
    }
  });
}
