import { ANDROID, IOS, LINUX, MAC, OTHERS, UNKNOWN, WINDOWS, WINDOWS_PHONE } from "./constant";

class DeviceDected {
  constructor(ua) {
    this.ua = ua || navigator.userAgent;
    this.deviceData = {
      platform: UNKNOWN, // (Desktop | Mobile)
      osType: OTHERS, //  操作系统
      osVersion: UNKNOWN, // 操作系统版本
      browserType: UNKNOWN, // 浏览器类型
      browserVersion: UNKNOWN, // 浏览器版本
      screenWidth: window.screen.width, // 屏幕宽度
      screenHeight: window.screen.height, // 屏幕高度
      userAgent: this.ua, // user-agent
      netStatus: UNKNOWN, // 网络情况
      dpr: window.devicePixelRatio, // dpr
      language: UNKNOWN, // 语言
      timeZone: new Date().getTimezoneOffset() / 60, // 时区
    };
    this.isMobile = false;
    this.init();
  }
  init() {
    this.checkIsMobile();
    this.getOsType();
    this.getOsVersion();
    if (this.isMobile) {
      this.getMobileModel();
    }
    this.getNetStatus();
    this.getBrowserInfo();
    this.getLanguage();
  }
  checkIsMobile() {
    const supportTouch = "ontouchstart" in window;
    const isMobileUA = !!this.ua.match(
      /iPhone|iPad|iPod|iOS|Android|Mobile|BlackBerry|IEMobile|Opera Mini|WebOS|Symbian|Windows Phone|\(Linux/gi
    );
    const supportOrientation = !!window.orientation;
    const isMobile = supportTouch || isMobileUA || supportOrientation;
    this.isMobile = isMobile;
    this.deviceData.platform = isMobile ? "Mobile" : "Desktop";
  }
  getOsType() {
    if (this.isMobile) {
      if (this.ua.match(/Windows Phone/gi)) {
        this.deviceData.osType = WINDOWS_PHONE;
      } else if (this.ua.match(/Android|adr|Linux|huawei|vivo|oppo/gi)) {
        this.deviceData.osType = ANDROID;
      } else if (this.ua.match(/iPhone|iPad|iPod|iWatch|iTV|iOS [\d.]+/gi)) {
        this.deviceData.osType = IOS;
      }
    } else {
      if (this.ua.match(/windows|win32|win64|wow32|wow64/gi)) {
        this.deviceData.osType = WINDOWS;
      } else if (this.ua.match(/macintosh|macintel/gi)) {
        this.deviceData.osType = MAC;
      } else if (this.ua.match(/x11/gi)) {
        this.deviceData.osType = LINUX;
      }
    }
  }
  getOsVersion() {
    let match = null;
    if (this.deviceData.osType === ANDROID) {
      // Android
      if ((match = this.ua.match(/(Android|adr)(\/|\s*)([\d.\-_+]+)/i))) {
        if (match[3]) this.deviceData.osVersion = match[3].replace(/_/g, ".") || UNKNOWN;
      }
    } else if (this.deviceData.osType === IOS) {
      // iOS
      if ((match = this.ua.match(/OS ([\d.\-_]+)/i))) {
        if (match[1]) this.deviceData.osVersion = match[1].replace(/_/g, ".") || UNKNOWN;
      }
    } else if (this.deviceData.osType === WINDOWS_PHONE) {
      // Windows Phone
      if ((match = this.ua.match(/Windows Phone( OS)? ([\d._]+)/i))) {
        if (match[2]) this.deviceData.osVersion = match[2].replace(/_/g, ".") || UNKNOWN;
      }
    } else if (this.deviceData.osType === WINDOWS) {
      // Windows
      if (this.ua.match(/NT 5.1|windows xp/gi)) {
        this.deviceData.osVersion = "Windows XP";
      } else if (this.ua.match(/NT 5.2|windows 2003/gi)) {
        this.deviceData.osVersion = "Windows 2003";
      } else if (this.ua.match(/NT 6.0|windows vista/gi)) {
        this.deviceData.osVersion = "Windows Vista";
      } else if (this.ua.match(/NT 6.1|windows 7/gi)) {
        this.deviceData.osVersion = "Windows 7";
      } else if (this.ua.match(/NT 6.2|windows 8/gi)) {
        this.deviceData.osVersion = "Windows 8";
      } else if (this.ua.match(/NT 6.3|windows 8.1/gi)) {
        this.deviceData.osVersion = "Windows 8.1";
      } else if (this.ua.match(/NT 10|windows 10/gi)) {
        this.deviceData.osVersion = "Windows 10";
      }
    } else if (this.deviceData.osType === MAC) {
      // Mac OS X
      match = this.ua.match(/Mac OS X[\s_\-/](\d+[.\-_]\d+[.\-_]?\d*)/i);
      if (match && match[1]) {
        this.deviceData.osVersion = match[1].replace(/_/g, ".") || UNKNOWN;
      }
    }
  }
  getBrowserInfo() {
    let match = null;
    if ((match = this.ua.match(/MicroMessenger\/([\w.]+)/i))) {
      this.deviceData.browserType = "Wechat Browser";
    } else if ((match = this.ua.match(/UCBrowser\/([\w.]+)/i))) {
      this.deviceData.browserType = "UC Browser";
    } else if ((match = this.ua.match(/miuibrowser\/([\w.]+)/i))) {
      this.deviceData.browserType = "MIUI Browser";
    } else if ((match = this.ua.match(/QQBrowser\/([\w.]+)/))) {
      this.deviceData.browserType = "QQ Browser";
    } else if ((match = this.ua.match(/Mobile\/\w{5,}\s+QQ\/([\w.]+)/i))) {
      this.deviceData.browserType = "Mobile QQ";
    } else if ((match = this.ua.match(/360se/i))) {
      this.deviceData.browserType = "Se360 Browser";
    } else if ((match = this.ua.match(/360ee/i))) {
      this.deviceData.browserType = "Ee360 Browser";
    } else if ((match = this.ua.match(/2345Explorer|2345chrome|Mb2345Browser\/([\w.]+)/i))) {
      this.deviceData.browserType = "2345 Browser";
    } else if ((match = this.ua.match(/lbbrowser/i))) {
      this.deviceData.browserType = "Liebao Browser";
    } else if ((match = this.ua.match(/maxthon\/([\w.]+)/i))) {
      this.deviceData.browserType = "Maxthon Browser";
    } else if ((match = this.ua.match(/(BIDUBrowser|baidubrowser|BaiduHD|baiduboxapp)\/([\w.]+)/i))) {
      this.deviceData.browserType = "Baidu Browser";
      if (match[2]) this.deviceData.browserVersion = match[2].replace(/_/g, ".");
    } else if ((match = this.ua.match(/metasr|sougou/i))) {
      this.deviceData.browserType = "Sougou Browser";
    } else if ((match = this.ua.match(/NokiaBrowser/i))) {
      this.deviceData.browserType = "Nokia Browser";
    } else if ((match = this.ua.match(/msie ([\w.]+)|Trident\/([\w.]+)/i))) {
      this.deviceData.browserType = "IE Browser";
      if (match[2]) this.deviceData.browserVersion = match[2].replace(/_/g, ".");
    } else if ((match = this.ua.match(/(opera|OPR)\/([\w.]+)/i))) {
      this.deviceData.browserType = "Opera Browser";
      if (match[2]) this.deviceData.browserVersion = match[2].replace(/_/g, ".");
    } else if ((match = this.ua.match(/edge\/([\w.]+)/i))) {
      this.deviceData.browserType = "Edge Browser";
    } else if ((match = this.ua.match(/firefox\/([\w.]+)/i))) {
      this.deviceData.browserType = "Firefox Browser";
    } else if ((match = this.ua.match(/chrome\/([\w.]+)/i))) {
      this.deviceData.browserType = "Chrome Browser";
    } else if ((match = this.ua.match(/safari\/([\w.]+)/i))) {
      this.deviceData.browserType = "Safari Browser";
    } else if ((match = this.ua.match(/__weibo__([\w.]+)/))) {
      this.deviceData.browserType = "Weibo Browser";
    } else if ((match = this.ua.match(/AppleWebKit(\/|\s*)?([\w.]+)/i))) {
      this.deviceData.browserType = "Webkit";
      if (match[2]) this.deviceData.browserVersion = match[2].replace(/_/g, ".");
    }
    if (match && match[1] && this.deviceData.browserVersion === UNKNOWN) {
      this.deviceData.browserVersion = match[1].replace(/_/g, ".");
    }
  }
  getMobileModel() {
    let model = UNKNOWN;
    let manufacturer = UNKNOWN;
    let match = null;
    if (this.deviceData.osType === IOS) {
      if ((match = this.ua.match(/(iPhone|iPad|iPod|iWatch|iTV)/i))) {
        if (match[1]) model = match[1];
      }
      manufacturer = "Apple";
    } else if (this.deviceData.osType === ANDROID) {
      if ((match = this.ua.match(/;\s*([^;]+)\s*Build\/(\w+)/i))) {
        // Mozilla/6.0(Linux; Android 6.0; HUAWEI NXT-AL10 Build/HUAWEINXT-AL10) AppleWebKit/537.36(KHTML,like Gecko) Version/6.0 Mobile Safari/537.36
        // Mozilla/5.0 (Linux; U; Android 4.4.2; zh-cn; Build/KOT49H ) AppleWebKit/534.30 (KHTML,like Gecko) Version/4.4.2 Mobile Safari/534.30 GiONEE-V188S/V188S RV/4.4.0 GNBR/5.0.0.t Id/077199E3C46A3195274FA8613013A2AA
        if (match[1]) {
          let mat = null;
          if ((mat = match[1].match(/([\w._\-+]+)(\/[\w._\-+]+)?( (Android|Release|Browser)\/[\w._\-+]+)+/i))) {
            // Mozilla/5.0 (Linux; Android 4.3; zh-cn; SAMSUNG-SM-N9008V_TD Release/11.15.2013 Browser/AppleWebKit537.36 Build/JSS15J) AppleWebKit/537.36 (KHTML, like Gecko) Version/1.5 Mobile Safari/537.36
            if (mat[1]) model = mat[1].trim();
          } else if (match[1].trim() !== "" && !/^\d+$/.test(match[1].trim())) model = match[1].trim();
          else if (match[2]) model = match[2].trim();
        }
      } else if (
        (match = this.ua.match(
          /^([\s\w._\-+]+)(\/[\s\w._\-+]+)?((\s+|\/)Linux\/[\w._\-+]+)? (Release|Android;?|adr)(\/|\s+)[\w._\-+]+/i
        ))
      ) {
        // DESAY TS1008/1.0 Release/07.12.2013 Mozilla/5.0 (Linux; U; Android 4.0.3; zh-cn) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30 baiduboxapp/5.2 (Baidu; P1 4.0.3)
        // HUAWEI_KIW-TL00_TD/5.0 Android/5.1 (Linux; U; Android 5.1; zh-cn) Release/09.02.2015 Browser/WAP2.0 (AppleWebKit/537.36) Mobile Safari/537.36
        // HTCD820ts_TD/1.0 Android/4.4 release/2014 Browser/WAP2.0 Profile/MIDP-2.0 Configuration/CLDC-1.1 AppleWebKit/534.30
        // Lenovo-A788t_TD/S100 Linux/3.4.39 Android/4.3 Release/08.15.2013 Browser/AppleWebkit534.30 Mobile Safari/534.30 baiduboxapp/4.2 (Baidu; P1 4.3)
        if (match[1]) model = match[1].trim();
      } else if (
        (match = this.ua.match(
          /\(Linux;(\s+U;| arm_?\d*;)?\s+(Android|adr)(\/|\s+)[^;]+;\s*(\w{2}-\w{2};)?\s*([^;()/]+)/i
        ))
      ) {
        // Mozilla/5.0 (Linux; Android 4.4.2; GN151) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/33.0.0.0 Mobile Safari/537.36 baidubrowser/5.3.4.0 (Baidu; P1 4.3.1) tieba/6.7.2 BMW
        if (match[5] && !/^\w{2}-\w{2}$/.test(match[5]) && match[5].trim() !== "") model = match[5];
      }
      manufacturer = "Google";
    } else if (this.deviceData.osType === WINDOWS_PHONE) {
      model = "Windows Phone";
      manufacturer = "Microsoft";
    }
    this.deviceData.mobileModel = model;
    this.deviceData.manufacturer = manufacturer;
  }
  getLanguage() {
    const language =
      navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage || UNKNOWN;
    this.deviceData.language = language;
  }
  getNetStatus() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection && connection.effectiveType) {
      this.deviceData.netStatus = connection.effectiveType;
    }
  }
}
export default DeviceDected;
