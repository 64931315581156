module.exports = {
  LOAD_EVENT: "load",
  UNLOAD_EVNET: "unload",
  PUSH_STATE_EVENT: "pushState",
  REPLACE_STATE_EVENT: "replaceState",
  HASH_CHANGE_EVENT: "hashchange",
  VISIBILITY_CHANGE_EVENT: "visibilitychange",
  AVAILABLE_REPORT_EVENTS: ["load", "unload", "pushState", "replaceState", "hashchange", "visibilitychange"],
  PERFORMANCE_PLUGIN: "performancePlugin",
  AVAILABLE_PLUGINS: ["performancePlugin", "debugPlugin"],
  AVAILABLE_ENV_LIST: ["dev", "test", "pre", "prod"],
  SHAREIT_BEYLA_ID: "shareit_beyla_id",
  IOS: "IOS",
  ANDROID: "Android",
  WINDOWS_PHONE: "Windows Phone",
  MAC: "Mac OS X",
  WINDOWS: "Windows",
  LINUX: "Linux",
  OTHERS: "Others",
  UNKNOWN: "Unknown",
};
