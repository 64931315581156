export function getAllUrlParams() {
  var url = location.search;
  const params = {};
  if (url.indexOf("?") != -1) {
    var str = url.substring(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      const keyAndValue = strs[i].split("=");
      params[keyAndValue[0]] = keyAndValue[1] || "";
    }
  }
  return params;
}
