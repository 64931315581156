import { AVAILABLE_ENV_LIST, PERFORMANCE_PLUGIN } from "./constant";

function getType(args) {
  return toString.call(args).slice(8, -1);
}
function checkString(str) {
  return typeof str === "string" && str.trim() !== "";
}
function checkTypeInvalid(options, key, type) {
  if (options[key] === undefined) return false;
  if (getType(options[key]) !== type) {
    throw new TypeError(`The ${key} parameter must be of type ${type}`);
  }
  return true;
}
// 对象包含自有属性
function hasOwnProperty(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}
// 属性值规范
// 三段式
function valueThreeStage(str) {
  // pveCur: "/home/x/x",校验三段式，且每一段不为空
  return /^\/.+\/.+\/.+/.test(str);
}

export function validateBeylaOptions(options) {
  const { appId, env, plugins } = options;
  if (getType(options) !== "Object") {
    throw new TypeError("The options parameter must be of type Object");
  }
  if (!checkString(appId)) {
    throw new TypeError("appId parameter must be of type String and cannot be empty");
  }
  const isValidEnvType = checkTypeInvalid(options, "env", "String");
  if (isValidEnvType && !AVAILABLE_ENV_LIST.includes(env)) {
    throw new TypeError("The env parameter must be one of dev, test, pre, prod");
  }
  const isValidPluginType = checkTypeInvalid(options, "plugins", "Array");
  if (isValidPluginType && plugins.some((plugin) => ![PERFORMANCE_PLUGIN].includes(plugin))) {
    throw new TypeError("The plugins parameter must be a subset of ['performancePlugin']");
  }
  checkTypeInvalid(options, "beforeSend", "Function");
  checkTypeInvalid(options, "strict", "Boolean");
  checkTypeInvalid(options, "devtools", "Boolean");
  return true;
}

export function validateBuriedPointParams(params) {
  function validator(params) {
    if (
      !(
        checkString(params.pveCur) &&
        hasOwnProperty(params, "pveCur") &&
        valueThreeStage(params.pveCur) &&
        checkString(params.eventName) &&
        hasOwnProperty(params, "eventName") &&
        ~["click_ve", "show_ve", "in_page", "out_page"].indexOf(params.eventName)
      )
    ) {
      throw new TypeError(
        "Strict mode is turned on by default for buried point. Please check the fields according to the specification. If you do not need to turn it on, please set the strict parameter to false."
      );
    }
  }
  if (getType(params) === "Object") {
    validator(params);
  } else if (Array.isArray(params)) {
    params.forEach((item) => validator(item));
  }
}
