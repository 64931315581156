export function getCookie(name) {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
}

export function setCookie(name, value, days) {
  try {
    if (document.domain) {
      document.domain = document.domain.split(".").slice(-2).join(".");
    }
  } catch (error) {
    console.error("set cookie error: " + error);
  }
  var exp = new Date();
  exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}
